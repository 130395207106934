.display-block {
    margin-top: 80px;
    margin-bottom: 80px;

    .optional-desc {
        font-size: 18px;
    }    

    .interaction-container {
        width: 40px;
        height: 40px;
        background-color: $custom_white;
        border-radius: 30px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        position: absolute;
        display: flex;
        justify-content: center;

        svg {
            color: $base-green;
            display: block;
            margin:auto;
        }
    }

    .content {
        position: relative;
    }

    .interaction-container:hover {
        background-color: $lightgray;
        cursor: pointer;
    }

    .people {
        left: 85px;
        bottom: -20px;
    }

    .favourite {
        top: 10px;
        right: 10px;
        background-color: RGBA(0, 0, 0, 0.1);
        box-shadow: none;
        svg {
            color: RGBA(255, 255, 255, 0.5);
        }
        //removed until a new decision from Andrew
        @media (min-width: 1000px) {
            //right: 25px;
        }
    }

    .favourite:hover {
        background-color: RGBA(0, 0, 0, 0.1);
    }

    .favourite-audio {
        top: 0px;
        right: 10px;
        background-color: none;
        box-shadow: none;
        svg {
            color: RGBA(0, 0, 0, 0.1);
        }
    }

    .comment {
        bottom: -20px;
        left: 36px;
    }

    .audio {
        right: 36px;
        bottom: -20px;
    }

    .disabled {
        background-color: $gray;
        svg {
            color: $lightgray;
        }
    }
    .disabled:hover {
        background-color: $gray;
        cursor: default;
    }

    .added {
        position: absolute;
        top: 45px;
        color: $gray;
    }

    .record-user {
        width: 200px;
        font-size: 14px;
        position: absolute;
        top: 45px;
        color: $gray;
    }

    .played {
        background-color: $base-green;

        svg {
            color: white;
        }
    }

    .played:hover {
        background-color: white;

        svg {
            color: $base-green;
        }
    }
}

.display-block-audio {
    text-align: center;
    padding-top: 20px;

    .optional-desc {
        font-size: 18px;
        color: rgba(23, 36, 61, 0.5);        
    }

    .play {
        background-color: $base-green;
    }

    .pause {
        background-color: RGB(239, 90, 90);
    }

    .backward {
        position: absolute;
        right: 140px;
        top: 5px;
        @media screen and (max-width: 769px) {
            //audio block backward and forward design fix in mobile screen
            right: 60px;
        }

        svg {
            //transform: scaleX(-1);
            color: RGBA(0, 0, 0, 0.1);
            &:hover {
                color: $base-green;
            }

            @media screen and (max-width: 769px) {
                width: 2.5rem!important;
                height: 2.5rem!important;
            }

        }
        
        span {
            position: absolute;
            left: 10px;
            top: 11px;
            font-size: 8px;
        }
    }

    .backward:hover {
        cursor: pointer;
        color: gray;
    }

    .forward {
        position: absolute;
        left: 140px;
        top: 5px;
        //audio block backward and forward design fix in mobile screen
        @media screen and (max-width: 769px) {
            left: 60px;
        }

        svg {
            color: RGBA(0, 0, 0, 0.1);
            &:hover {
                color: $base-green;
            }
            @media screen and (max-width: 769px) {
                width: 2.5rem!important;
                height: 2.5rem!important;
            }
        }

        span {
            position: absolute;
            left: 10px;
            top: 11px;
            font-size: 8px;
        }
    }

    .forward:hover {
        cursor: pointer;
        color: gray;
    }

    .record-button {
        width: 64px;
        height: 64px;
        border-radius: 40px;
        border: none;
        //margin-bottom: 10px;

        svg {
            color: white;
        }
    }

    .audio-title {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: 20px;
        margin: 10px 0;
        @media (min-width: 1000px) {
            font-size: 24px;
        }
    }

    .audio-user {
        font-size: 14px;
        font-weight: 400;
        color: $gray;
        letter-spacing: 0;
        line-height: 18px;
        padding-top: 10px;        
    }

    .wave {
        height: 0px;
        display: none;
    }

    .optional-desc{
        padding-top: 30px;
    }
}

.display-block-text {

    .empty {
        height: 18px;
    }

    span {
        font-size: 16px !important;
        font-weight: 400 !important;
        color: $base !important;
        font-family: $font !important;
        @include display-story-text();
    }

    p {
        font-size: 16px;
        @include display-story-text();
        font-weight: 400 !important;
        color: $base !important;
        font-family: $font !important;
        line-height: 1.5;

        a {
            color: $base-green !important;
            transition: $transition;
        
            &:hover {
                color: $base-green-hover !important;
            }
        }        
    }
}

.co-author-block-text {
    position: relative;
    border: 1px solid rgba(23,36,61,0.12);
    @include story-box-small();

    .co-author {
        position: absolute;
        text-align: center;
        background-color: white;
        padding-left: 15px;
        padding-right: 15px;

        color: rgba(23,36,61,0.7);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;

        left: 50%;
        transform: translate(-50%, -50%);
    }

    .text-data {        
        padding: 40px;

        p {
            font-size: 16px;
            @media (min-width: 1000px) {
                font-size: 18px;
            }
            font-style: italic;
            letter-spacing: 0.5px;
            line-height: 24px;           
        }
        
    }
}

.co-author-display-block-lifelesson {
    position: relative;

    .co-author {
        position: absolute;
        text-align: center;
        background-color: white;
        padding-left: 15px;
        padding-right: 15px;

        color: rgba(23,36,61,0.7);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        top: -15px;

        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
    }
}

.display-block-lifelesson {
    background-color: $gold;
    color: $secondary;
    padding: 20px;
    text-align: center;
    padding: 24px 20px 24px 20px;
    position: relative;
    @media (min-width: 1000px) {
        @include story-box-small();
    }

    .icon {
        top: 12px;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        width: 30px;
        background-color: $gold;
    }

    .container {
        padding: 35px;
        border: 1px solid white;

        p {
            margin-bottom: 0;
            font-size: 12px;    
            letter-spacing: 0.4px;
        }

        p:first-child{
            font-size: 18px;
            //width: 90%;
            text-align: center;
            margin: 0 auto;
            line-height: 24px;     
            font-style: italic;
            letter-spacing: 0.5px;     
            font-weight: 100;
            @media (min-width: 1000px) {
                font-size: 24px;
                letter-spacing: 0.48px;
                line-height: 30px;
            }  
        }        

        p:nth-child(2){
            padding-top: 20px;
            color: rgba(255,255,255,0.7);
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
            .tag {
                .underlined-link-button-tags {
                    color: rgba(255,255,255,0.7)!important;
                    font-size: 12px;
                    letter-spacing: 0;
                    line-height: 18px;
                    text-decoration: none;
                }
            }
        }

        .tag {

            display: inline;
        
            .underlined-link-button {
                font-size: inherit !important;
                color: $secondary !important;

                &:hover {
                    color: rgba($secondary, 0.65) !important;
                    cursor: pointer !important;
                }                
            }
        }

    }
}

.display-block-attachment {
    color: $gray;
    text-align: center;

    padding: 24px 16px 24px 16px;
    position: relative;
    @media screen and (min-width: 1000px) {
        padding: 24px 0 24px 0;
    }

    @include story-box-small();
    .icon {
        float: left;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 30px;
        color: $gray;        
    }

    .container {
        padding: 35px;
        border: 1px solid rgba(23, 36, 61, 0.1);
        overflow: hidden;

        .attachment {
            float: left;
            text-align: left;
            width: 400px;
        }

        p {
            letter-spacing: 0.4px;
            margin: 0 auto;
            padding-left: 10px;            
        }

        p.title {
            padding-bottom: 30px;
        }
        
        p.size {
            font-size: 12px;
        }           

    }
}

.display-block-linkalbum {
    color: $gray;
    text-align: center;
    @include story-box-small();
    //padding: 24px 16px 24px 16px;
    position: relative;

    .container {
        padding: 35px;
        border: 1px solid rgba(23, 36, 61, 0.1);
        overflow: hidden;

        p {
            letter-spacing: 0.4px;
            margin: 0 auto;         
        }

        p.title {
            padding-bottom: 30px;
        }

        .album {

            .view {
                text-transform: uppercase;
                color: $base-green;
                padding-bottom: 15px;
            }
        }

    }
}

.display-block-embed {
    color: $gray;
    text-align: center;

    //adeed small width to the embed block
    // andrew: TEXT BLOCKS FOR COAUTHORS / LIFE LESSON BLOCK / ATTACHMENTS BLOCK / EMBED BLOCK / AUDIO BLOCKS: set a 'max width' so these are all the same width on the page. Quite narrow compared to images and text. Its the same width as the Comments section at the bottom of the page
    @include story-box-small();

    padding: 24px 0 24px 0;
    position: relative;

    .container {
/*        padding: 35px;
        border: 1px solid $gray;
        */
        overflow: hidden;
        padding: 0 !important;

        p {
            letter-spacing: 0.4px;
            margin: 0 auto;         
        }

        p.title {
            padding-bottom: 10px;
        }

        .album {

            .view {
                text-transform: uppercase;
                color: $base-green;               
            }

            iframe {
                @media only screen and (max-width: 500px) {
                    max-width: 335px;
                }
            }    
        }

    }
}

.display-block-video {
    .people {
        left: 85px;
        bottom: -15px;
    }

    .comment {
        left: 36px;
        bottom: -15px;
    }

    .audio {
        right: 36px;
        bottom: -15px;
    }

    .content {
        box-shadow:none!important;
    }
}

.display-block-media {
    text-align: center;

    .video {
        width: 100%;
        height: auto;
    }

    .optional-desc {
        font-size: 18px; /* 14px */
        font-weight: 400;
        color: $gray;
        margin-bottom: 20px;
    }

    .content.one-image-in-gallery {
        .story-block-image   {
            @include media-default-settings-one-img();
        }
    }

    .content {
        .story-block-image   {
            @include media-default-settings();
        }
    }

    .carousel-indicators {
        margin-bottom: 0;
        bottom: -20px;
        .active {
            background-color: $base-green!important; 
        }
        @media only screen and (max-width: 790px) {
            bottom: -40px;
        }
    }

    .carousel-indicators [data-bs-target] {
        width: 6px;
        height: 6px;
        background-color: rgba(23,36,61,0.3);
        border-radius: 5px;
        border: 0;

        display: flex;
        align-items: center;
    }

    button[aria-label="big-dot"] {
        width: 6px !important;
        height: 6px !important;
    }

    button[aria-label="middle-dot"] {
        width: 4px !important;
        height: 4px !important;
    }

    button[aria-label="small-dot"] {
        width: 2px !important;
        height: 2px !important;
    }

    button[aria-label="hidden-dot"] {
        display: none !important;
    }

    .carousel {
        .carousel-control-prev-icon, .carousel-control-next-icon {
            box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12);
            background-color: RGBA(0, 0, 0, 0.1);
        }
    }

}

.display-block-carousel {
    text-align: center;

    .current-page {
        padding-top: 30px;
        font-size: 12px;
        font-weight: 400;
        color: $gray;
        @media only screen and (max-width: 790px) {
            padding-top: 50px;
        }
    }

    .story-block-video {
        height: 490px;

    }
    .story-block-media {
        height: 500px;
        width: auto;
    }

    .carousel-control-prev,
    .carousel-control-next {
        height: 20%;
        top: 40%;
        /*top: 100%; cartodo */
        width: 100px;
    }

    .carousel-control-prev {
        left: 5px;  /* 2% cartodo */
    }
    .carousel-control-next {
        right: 5px; /* 70% cartodo */
    }
/* cartodo
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238B919D'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
    }
    .carousel-control-next-icon  {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238B919D'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");    
    }    
*/
    .people {
        left: 85px;
        bottom: -20px;
    }

    .comment {
        left: 36px;
        bottom: -20px;
    }

    .audio {
        right: 36px;
        bottom: -15px;
    }
}

.modal-dialog.members-modal-display {
    @include desktop-medium-sized-model();
    // modal medium size - Story media (photo or video) > Featured people. 
}

.members-modal-display {

    .modal-body {
        padding: 0;

    }

    img {
        width: 100%;
    }

    div {
        overflow: hidden;
    }

    .family {
        border-bottom: 1px solid rgba(23, 36, 61, 0.1);

        .tagged-user {
            text-decoration: underline;
            color: $base-green;
        }

        .tagged-user:hover {
            color: $base-green-hover;
            cursor: pointer;
        }

    }

    .tagged {
        margin: 20px
    }
    
    p {
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
    }
    
    p:nth-of-type(2n + 1) {
        float: left;
        color: $gray;
    }

    p:nth-of-type(2n) {
        float: right;
    }
}

.display-audio-interaction {
    position: relative;

    .record-user {
        width: 100px;
        font-size: 14px;
        position: absolute;
        top: 45px;
        color: $gray;
        right: -32px;
        // left: -80px;
        text-align: center;
    }

    .wave {
        height: 0px;
        display:none;
    }
}

.story-media-collage {
    position: relative;
    margin-bottom: 20px;
    
    .video-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.3);
        color: white;
    }

    .remaining-count {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background: rgba(0, 0, 0, 0.7);
        color: white;
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 12px;
    }
}
