.story-item {
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}

.story-item-content {
  cursor: pointer;
}

.story-item .story-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.story-item .story-title {
  font-size: 16px;
  font-weight: 500;
  color: #17243d;
  margin: 0;
}

.story-item .action-container {
  color: rgba(23, 36, 61, 0.5);
}

.story-item .story-content {
  margin-bottom: 15px;
}

.story-item .story-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.story-item .author-info {
  display: flex;
  align-items: center;
}

.story-item .user-picture {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 8px;
}

.story-item .author-details {
  display: flex;
  flex-direction: column;
}

.story-item .author-name {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
}

.story-item .story-date {
  font-size: 12px;
  color: rgba(23, 36, 61, 0.5);
  margin: 0;
}

.story-item .read-time {
  display: flex;
  align-items: center;
  color: rgba(23, 36, 61, 0.5);
  font-size: 12px;
}

.story-item .read-time span {
  margin-left: 4px;
}

.story-item-container.simplified-layout {
  padding: 15px;
  max-width: 725px;
}

.story-item-container.simplified-layout .story-item-content {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-bottom: 0 !important;
  padding: 6px !important;
  cursor: pointer;
}

.story-item-container.simplified-layout .story-item-header p {
  margin-bottom: 0;
}

.story-item-container.simplified-layout .story-item-header .action-container {
  top: 0px !important;
  right: 15px !important;
}

.story-item-container.simplified-layout .story-item-info.simplified {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
}

.story-item-container.simplified-layout .story-item-title {
  font-size: 18px !important;
  text-align: left;
  margin-bottom: 20px !important;
  font-weight: 500;
  color: #2e7d32;
  padding: 0 !important;
}

.story-item-container.simplified-layout .author-info {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.story-item-container.simplified-layout .author-left {
  display: flex;
  align-items: center;
}

.story-item-container.simplified-layout .user-picture {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50%;
  margin-right: 8px;
}

.story-item-container.simplified-layout .user-name {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  font-weight: 600 !important;
}

.story-item-container.simplified-layout .story-date {
  font-size: 14px;
  color: rgba(23, 36, 61, 0.5);
  margin: 0;
}
