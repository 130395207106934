.all-published-container {

    @media only screen and (min-width: 992px) {
        min-height: 39vh;
    }

    .all-stories {
        background-color: $lightgray;
        padding-bottom: 40px;
        position: relative;
        /* border-bottom: 1px solid rgba(23, 36, 61, 0.1); */
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        @media screen and (max-width: 500px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @include content-min-height();

        .has-more {
            text-align: center;
            font-size: 12px;
            color: $gray;
        }

        .active-filter-container {
            margin: auto;
            text-align: center;
            position: relative;
            max-width: 700px;

            .remove:hover {
                cursor: pointer;
                svg {
                    color: $lightgray;
                }
            }

            .active-filter-box {
                margin: 4px;
                display: inline-flex;
                background-color: white;
                border: 1px solid rgba(23,36,61,0.1);
                border-radius: 20px;
                padding: 3px;

                svg {
                    color: $gray;
                    margin-left: 5px;
                    margin-right: 5px;
                }

                p {
                    color: $gray;
                    margin-right: 5px;
                    margin-left: 5px;
                    margin-bottom: 0;
                    font-size: 12px;
                    letter-spacing: 0.25px;
                    line-height: 20px;
                }
            }
        }
        
        .story-count {
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: $gray;
            margin: 24px 0;
        }

        .stories-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .layout-change {
                .icon {
                    color: rgba(23, 36, 61, 0.2);
                    margin-left: 10px;
                    cursor: pointer;

                    &.active {
                        color: $gray;
                    }

                    &:hover {
                        color: $base-green;
                    }
                }
            }
        }

        .stories-grid {
            .my-masonry-grid {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                margin-left: -30px;
                width: auto;
                gap: 0;
            }

            .my-masonry-grid_column {
                padding-left: 30px;
                background-clip: padding-box;
            }

            .my-masonry-grid_column > div {
                margin-bottom: 30px;
            }
        }

        .story-container {
            background-color: white;
            height: 120px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            padding: 20px ;
            position: relative;
            margin: 0 auto 20px auto;
            max-width: 700px;
            
            @include desktop-box-max-width();  

            cursor: pointer;

            p {
                margin-bottom: 0;
            }

            .action:hover {
                cursor: pointer;
            }

            .flair {
                position: absolute;
                bottom: 20px;
                right: -10px;
                width: 30px;
                height: 21px;
            }
            
            .story-title {
                font-size: 16px;
                font-weight: 500;
                color: $base-green;
                margin-bottom: 10px;
            }

            .img-author {
                position: absolute;
                bottom: 20px;
                left: 20px;
                
                img {
                    height: 25px;
                    width: 25px;
                }
                
                .story-author {
                    display: inline-block;
                    margin-left: 8px;
                    font-size: 12px;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
            }

            .story-date {
                color: $gray;
                position: absolute;
                bottom: 20px;
                right: 30px;
            }
        }

        .bordered-button {
            margin-top: 80px;
            margin-bottom: 40px;
        }

    }
}