.story-media-collage {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  border-radius: 4px;
}

.media-grid {
  display: grid;
  gap: 4px;
  width: 100%;
  height: 100%;
}

/* Layout for a single image */
.story-media-collage.single-image .media-grid {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 250px;
}

.story-media-collage.single-image .media-item.centered {
  width: 100%;
  height: 100%;
}

/* Layout for 2 images - full width, 1 row */
.story-media-collage.two-images .media-grid {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  height: 250px;
}

/* Layout for 3 images - 1 row with 1 image, 1 row with 2 images */
.story-media-collage.three-images .media-grid {
  grid-template-areas: 
    "img1 img1"
    "img2 img3";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: 400px;
}

.story-media-collage.three-images .media-item:nth-child(1) {
  grid-area: img1;
}

.story-media-collage.three-images .media-item:nth-child(2) {
  grid-area: img2;
}

.story-media-collage.three-images .media-item:nth-child(3) {
  grid-area: img3;
}

/* Layout for 4+ images - 2x2 grid */
.story-media-collage.many-images .media-grid {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: 400px;
}

.media-item {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.media-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-thumbnail {
  position: relative;
  width: 100%;
  height: 100%;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/* Styling for the last item with remaining count */
.media-item.last-item {
  position: relative;
  overflow: hidden;
}

.remaining-count-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  z-index: 3;
}

.remaining-count-overlay span {
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
}

/* Adjust for dashboard layout */
.story-media-collage.dashboard {
  max-height: 350px;
  overflow: hidden;
}

/* Adjust for single layout */
.story-media-collage.single {
  max-height: 450px;
  overflow: hidden;
}